
<template>

  <div>
    <page-banner>
      <template #banner-txt>
        捐款
      </template>
      <template #banner-img>
        <img v-lazy="banner" class="img-center">
      </template>
    </page-banner>

    <div class="common-bg padding-50">
      <div class="container">
        <div class="donate">
          <h1>
            請選擇捐款方式：
          </h1>
          <div class="donate-way">
            <div class="donate-way-part">
              <div class="pay-1" style="position: relative">
                <div class="donate-way-title">
                  <div class="donate-way-title-left">
                    <span>01</span>
                    <div>
                      <h3>{{ list1.title }}</h3>
                      <p>{{ list1.info }}</p>
                    </div>
                  </div>
                  <div>
                    <img src="@/assets/img/tolink.svg">
                  </div>
                </div>
                <!--donate link-->
                <form action="https://iservice.boccc.com.hk/EWTNGOWEB/singleindex.do" method="POST">
                  <input type="hidden" id="ngoid" name="ngoid" value="100023">
                  <input type="hidden" id="ngoidsign" name="ngoidsign" value="46c7f52a5531194f8583e3274af268e704f294eb530bc751bc74880811c3bfba">
                  <input type="hidden" id="lang" name="lang" value="tc">
                  <button type="submit" class="donate-btn" style="">
                    donate link
                  </button>
                </form>
                <!--donate link-->



                <div class="pay-pic pay-way">
                  <img src="@/assets/img/pay-way.png">
                </div>

              </div>


              <div class="pay-1">
                <div class="donate-way-title">
                  <div class="donate-way-title-left">
                    <span>02</span>
                    <div>
                      <h3>{{ list2.title }}</h3>
                      <p>{{ list2.info }}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="pay-1">
                <div class="donate-way-title">
                  <div class="donate-way-title-left">
                    <span>03</span>
                    <div>
                      <h3>現金捐款</h3>
                      <p>{{ list3.info }}</p>
                    </div>
                  </div>
                </div>
                <div class="pay-pic">
                  <a :href="list3.link" target="_blank">
                    <img :src="list3.image">
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="do-tips">
            <div v-html="info"></div>
          </div>

        </div>
      </div>
    </div>

  </div>




</template>

<script>
import pageBanner from "@/components/PageBanner";

import {getDonate} from "@/network/home";

export default {
  name: "Donate",
  components: {
    pageBanner,
  },
  data(){
    return {
      banner:{},
      list1:[],
      list2:[],
      list3:[],
      info: {},
    }
  },
  created() {
    this.getDonate();
  },
  methods: {

    getDonate() {
      getDonate().then(res =>{//.then可以拿到数据
        console.log(res)
        //banner
        this.banner = res.data.data.banner;
        this.list1 = res.data.data.list[0];
        this.list2 = res.data.data.list[1];
        this.list3 = res.data.data.list[2];
        this.info = res.data.data.info;
      })
    },
  }
}
</script>

<style scoped>
.donate h1{
  color: #DE002B;
  font-weight: bolder;
  padding-bottom: 50px;
}
.donate-way-part{
  display: flex;
  flex-wrap: wrap;
}

.pay-1{
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid #D3C6BA;
}


.donate-way-title{
  display: flex;
}
.donate-way-title-left{
  display: flex;
  align-items: center;
}

.pay-way{
  background: #fff;

}

.pay-pic{
  margin: 20px 0;
  display: flex;
  justify-content: center;
}
.pay-pic p{
  padding-top: 10px;
}


.donate-way-title{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.donate-way-title span{
  font-size: 48px;
  margin-right: 15px;
  color: #D3C6BA;
  font-weight: bold;
}

.donate-way-title h3{
  font-weight: bolder;
  color: #000;
}

.donate-way-title p{
  font-size: 14px;
  color: #534649;
}
.do-tips{
  background: #FFFFFF;
  border: 1px solid #D3C6BA;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 15px 30px;
  margin-top: 40px;
}
.do-tips h4{
  color: #F76F01;
}
.do-tips p{
  color: #666;
}

.donate-btn{
  background: transparent;
  border: 0;
  cursor: pointer;
  position: absolute;
  width: 100%;
  height:87px;
  top: 0;
  bottom:0;
  right: 0;
  left: 0;
  opacity: 0;
}

@media only screen and (min-width:0px) and (max-width:991px) {
  .donate h1{
    font-size: 20px;
    padding-bottom: 10px;
  }



  .do-tips{
    margin-top: 20px;
  }
  .pay-pic{
    padding: 10px 0;

  }

}

</style>